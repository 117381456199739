<template>
  <div
    v-if="!hidden"
    class="member-level-keep-condition-block side-highlight-container"
    :class="{disabled: !formData.enable}"
  >
    <div style="margin-bottom: 20px">
      <div class="flex justify-between items-center">
        <p class="card-title" style="margin-bottom: 0px">續等條件</p>
        <el-switch
          v-if="!disabled"
          v-model="formData.enable"
          inactive-text="關閉"
          active-text="開啟"
        />
      </div>
      <p class="text-sm">
        在該會員等級有效期間內，當滿足條件時，系統將自動將該會員之會員等級以期限設定進行延長
      </p>
    </div>
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="top"
    >
      <LevelCondition :disabled="formData.enable" :formData.sync="formData" @clear="onClearValidation" />
      <LevelChannelFields
        label="續等計算適用消費管道"
        :disabled="formData.enable"
        :formData.sync="formData"
        @clear="onClearValidation"
      />
    </el-form>

    <div v-if="features.useExternalTransaction || features.usePospal" class="hint flex items-center" style="gap: 8px">
      <p>提醒：請留意第三方交易規則說明：</p>
      <router-link :to="{name: 'PosPospal'}" target="_blank">
        <p class="underline">瑞乘 ( 銀豹 ) POS</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, nextTick, watch, computed, ref, reactive, set } from 'vue'
import LevelCondition from './LevelConditionFields.vue'
import LevelChannelFields from './LevelChannelFields.vue'
import { isDigitRules, minRules, noEmptyRules } from '@/validation'
import FormsContext from '../formsContext'
import { get, toNumber, pick } from 'lodash'
import formUtils from '@/utils/form'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'MemberLevelKeepConditionBlock',
  components: { LevelCondition, LevelChannelFields },
  props: {
    configData: { type: Object, default: () => ({}) },
    hidden: Boolean,
    disabled: Boolean,
    features: { type: Object, default: () => ({}) },
  },
  emits: [],
  setup (props, { emit }) {
    const route = useRoute()
    const formRef = ref(null)
    const memberLevelId = computed(() => route.params.id)

    const formData = reactive({
      enable: false,
      channels: [],
      conditionType: null,
      months: null,
      amount: null,
    })

    const formRules = computed(() => {
      if (!formData.enable) return {}
      const rules = {
        channels: [noEmptyRules()],
        conditionType: [noEmptyRules()],
        months: [noEmptyRules(), isDigitRules(), minRules(1)],
        amount: [noEmptyRules(), isDigitRules(), minRules(1)],
      }
      // handle rules here

      return rules
    })

    watch(formRules, () => {
      setTimeout(() => {
        if (props.hidden) return
        formUtils.clearValidate(formRef.value)
      }, 0)
    })

    const compactData = computed(() => {
      const data = formData
      if (!get(data, 'enable')) {
        return {
          levelRemainCondition: 'none',
          levelRemainPeriod: null,
          levelRemainThreshold: null,
          levelRemainSources: [],
        }
      }
      // data handle here
      return {
        levelRemainCondition: get(data, 'conditionType'),
        levelRemainPeriod: toNumber(get(data, 'months')) || null,
        levelRemainThreshold: toNumber(get(data, 'amount')) || null,
        levelRemainSources: get(data, 'channels'),
      }
    })

    const onClearValidation = () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 0)
    }

    watch(compactData, () => {
      FormsContext.setFormData('levelKeepCondition', compactData.value)
    })

    const syncData = () => {
      const keysMap = {
        levelRemainCondition: 'conditionType',
        levelRemainSources: 'channels',
        levelRemainPeriod: 'months',
        levelRemainThreshold: 'amount',
      }
      const data = pick(props.configData, Object.keys(keysMap))
      for (const key in props.configData) {
        set(formData, get(keysMap, key), get(data, key))
      }

      const conditionType = get(data, 'levelRemainCondition')
      if (conditionType !== 'none') {
        formData.enable = true
      }
      if (conditionType === 'none') {
        formData.conditionType = null
      }
    }

    onMounted(async () => {
      await nextTick()
      if (!props.hidden) {
        FormsContext.setFormRef('levelKeepCondition', formRef.value)
      }
      if (memberLevelId.value) syncData()
      FormsContext.setFormData('levelKeepCondition', compactData.value)
    })

    return {
      formRef,
      formData,
      formRules,
      onClearValidation,
    }
  },
})
</script>

<style scoped lang="postcss">
.hint {
  @apply text-sub text-gray-80;
}
</style>
